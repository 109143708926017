import React from 'react'
import { graphql, Link } from 'gatsby'
import { StaticImage, GatsbyImage } from 'gatsby-plugin-image'
import { useI18next } from 'gatsby-plugin-react-i18next'
import Layout from '../components/layout'
import { getLocaleFromLanguage } from '../utils'

const getGroupPages = (group, locale) => {
  return group.landingPages
    .filter((page) => page.node_locale === locale)
    .sort((a, b) => {
      if (a.createAt > b.createAt) {
        return 1
      }
      return -1
    })
}

export default function Topics({ data }) {
  const { t, language } = useI18next()
  const locale = getLocaleFromLanguage(language)
  const groups = data?.allContentfulLandingGroup.nodes.filter(
    (group) => group.node_locale === locale
  )

  return (
    <Layout>
      <StaticImage
        src="../assets/images/topics.jpeg"
        alt=""
        className="w-full max-h-[50vh] mb-8 min-h-[30vh]"
        placeholder="tracedSVG"
      />
      <div className="w-full flex flex-col items-center px-2 md:px-0">
        <h1 className="text-3xl lg:text-5xl mb-12 md:mb-16">
          {t('latestTopics')}
        </h1>
        <div className="flex flex-col gap-12 items-center justify-center w-full md:w-3/4">
          {groups.map((group) => {
            return (
              <section key={group.title} className="flex flex-col items-center">
                <h3 className="text-2xl lg:text-3xl w-3/4 mb-4 pb-2 md:pb-4 text-center text-violet-800 border-b-sky-600 border-b-2 font-bold">
                  {group.title}
                </h3>
                {getGroupPages(group, locale).map((page) => {
                  return (
                    <section
                      className="prose prose-h2:mt-2 shadow-lg hover:shadow-xl m-2 p-6 w-full"
                      key="{page.slug}"
                    >
                      <Link to={page.slug} className="no-underline">
                        <h3>{page.pageHeading}</h3>
                        <GatsbyImage
                          alt=""
                          image={page.heroImage?.gatsbyImageData}
                        />
                        <p>{page.description?.description}</p>
                        <p className="underline text-violet-800">
                          {t('learnMore')}
                        </p>
                      </Link>
                    </section>
                  )
                })}
              </section>
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query TopicsPageQuery($language: String!) {
    allContentfulLandingGroup(sort: { fields: createdAt, order: DESC }) {
      nodes {
        title
        createdAt
        landingPages {
          slug
          pageHeading
          createdAt
          description {
            description
          }
          node_locale
          heroImage {
            gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
          }
        }
        node_locale
      }
    }
    locales: allLocale(
      filter: { ns: { in: ["common", "topics"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
